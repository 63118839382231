import React from 'react'
import './index.scss'

export default ({ classNameName = '' }) => (
  <div className="map-container">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1080 1920"
      className="venue-map"
    >
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M37.8,781.6h152.5v14.1H37.8V781.6z M369.6,1254.9l-229.2-37.5l-1.8-2.6c0,0-39.1-56.8-48.9-71H31.6v-14.4
				h62c3.9,0,4.9,1.8,8.2,6.4l47.1,68.4L372,1241L369.6,1254.9L369.6,1254.9z"
            />
            <path className="st0" d="M161.2,1128.2h5.4v8.2h-5.4V1128.2z" />
            <path className="st0" d="M221.6,1128.2h5.4v8.2h-5.4V1128.2z" />
            <path className="st0" d="M281.1,1128.2h5.4v8.2h-5.4V1128.2z" />
            <path
              className="st0"
              d="M52.9,1128.5l54.5-334.9l1.5,0.3l-54.5,334.9L52.9,1128.5z"
            />
            <path
              className="st0"
              d="M57.6,1128.2l54.5-334.9l1.5,0.3l-54.5,334.9L57.6,1128.2z"
            />
            <path
              className="st0"
              d="M62.4,1128.2l54.5-334.9l1.5,0.3l-54.5,334.9L62.4,1128.2z"
            />
            <path
              className="st0"
              d="M67.3,1128.5l54.5-334.9l1.5,0.3l-54.5,334.9L67.3,1128.5z"
            />
            <path
              className="st0"
              d="M72,1128.2l54.5-334.9l1.5,0.3l-54.5,334.9L72,1128.2z"
            />
            <path
              className="st0"
              d="M79.9,1128.5l54.5-334.9l1.5,0.3l-54.5,334.9L79.9,1128.5z"
            />
            <path
              className="st0"
              d="M84.6,1128.2l54.5-334.9l1.5,0.3l-54.5,334.9L84.6,1128.2z"
            />
            <path
              className="st0"
              d="M89.4,1128.5l54.5-334.9l1.5,0.3l-54.5,334.9L89.4,1128.5z"
            />
            <path
              className="st0"
              d="M94.1,1128.2l54.5-334.9l1.5,0.3l-54.5,334.9L94.1,1128.2z"
            />
            <path
              className="st0"
              d="M99,1128.5l54.5-334.9l1.5,0.3l-54.5,334.9L99,1128.5z"
            />
            <path
              className="st0"
              d="M279.9,661.6h5.9v5.9h-5.9V661.6z M279.9,746.5h5.9v5.9h-5.9V746.5z M336.2,746.5h35.5v5.9h-35.5V746.5z
				 M424.9,746.5h5.7v5.9h-5.7V746.5z M336.2,787.6h35.5v5.9h-35.5V787.6z M336.2,831.9h35.5v5.9h-35.5V831.9z M336.2,917.9h35.5
				v5.9h-35.5V917.9z M336.2,1003.2h35.5v5.9h-35.5V1003.2z M336.2,1047.7h35.5v5.9h-35.5V1047.7z M336.2,1089.1h35.5v5.9h-35.5
				V1089.1z M336.7,1090.7v-85.9h5.2v85.9H336.7z M336.7,788.6v-38.3h5.2v38.3H336.7z"
            />
            <path
              className="st0"
              d="M336.2,792.3h3.1v39.6h-3.1V792.3z M328.2,848.8l-0.5-0.5l9.3-9.5l0.8,0.8L328.2,848.8z M327.7,854.8
				l0.5-0.5l9.5,9.5l-0.8,0.5L327.7,854.8z M357.5,848.8l-0.5-0.5l9.3-9.5l0.8,0.8L357.5,848.8z M357,854.8l0.5-0.5l9.5,9.5
				l-0.8,0.5L357,854.8z M328.2,875.8l-0.5-0.8l9.3-9.3l0.8,0.5L328.2,875.8z M327.7,881.8l0.5-0.5l9.5,9.3l-0.8,0.8L327.7,881.8z
				 M357.5,875.8L357,875l9.3-9.3l0.8,0.5L357.5,875.8z"
            />
            <path
              className="st0"
              d="M356.7,881.8l0.5-0.5l9.5,9.5l-0.5,0.5L356.7,881.8z"
            />
            <path
              className="st0"
              d="M328.2,903.2l-0.5-0.8l9.3-9.5l0.8,0.8L328.2,903.2z M327.7,909.1l0.5-0.8l9.5,9.5l-0.8,0.5L327.7,909.1z
				 M357.5,903.2l-0.5-0.8l9.3-9.5l0.8,0.8L357.5,903.2z"
            />
            <path
              className="st0"
              d="M356.7,909.1l0.5-0.5l9.5,9.5l-0.5,0.5L356.7,909.1z"
            />
            <path
              className="st0"
              d="M328.2,934.1l-0.5-0.5l9.3-9.5l0.8,0.8L328.2,934.1z M327.7,940l0.5-0.5l9.5,9.3l-0.8,0.8L327.7,940z
				 M357.5,934.1l-0.5-0.5l9.3-9.5l0.8,0.8L357.5,934.1z"
            />
            <path
              className="st0"
              d="M356.7,940l0.5-0.5l9.5,9.5l-0.5,0.5L356.7,940z"
            />
            <path
              className="st0"
              d="M328.2,961.1l-0.5-0.8l9.3-9.3l0.8,0.5L328.2,961.1z M327.7,967l0.5-0.8l9.5,9.5l-0.8,0.5L327.7,967z
				 M357.5,961.1l-0.5-0.8l9.3-9.3l0.8,0.5L357.5,961.1z"
            />
            <path
              className="st0"
              d="M356.7,967l0.5-0.5l9.5,9.5l-0.5,0.5L356.7,967z"
            />
            <path
              className="st0"
              d="M327.7,987.6l9.5-9.5l0.5,0.5l-9.5,9.5L327.7,987.6z"
            />
            <path
              className="st0"
              d="M327.7,994l0.5-0.5l9.5,9.5l-0.8,0.5L327.7,994z"
            />
            <path
              className="st0"
              d="M356.7,987.6l9.5-9.5l0.5,0.5l-9.5,9.5L356.7,987.6z"
            />
            <path
              className="st0"
              d="M356.7,994.2l0.5-0.5l9.5,9.5l-0.5,0.5L356.7,994.2z"
            />
            <path
              className="st0"
              d="M421.8,831.9h8.5v5.9h-8.5V831.9z M421.8,917.9h8.5v5.9h-8.5V917.9z M421.8,1003.2h8.5v5.9h-8.5V1003.2z
				 M421.8,959.3h8.5v5.9h-8.5V959.3z M421.8,873.3h8.5v5.9h-8.5V873.3z M649.5,831.9h11.8v5.9h-11.8V831.9z M649.5,917.9h11.8v5.9
				h-11.8V917.9z M649.5,1003.2h11.8v5.9h-11.8V1003.2z M649.5,959.3h11.8v5.9h-11.8V959.3z M649.5,873.3h11.8v5.9h-11.8V873.3z
				 M581.4,832.9h5.9v5.9h-5.9V832.9z M581.4,1003.8h5.7v5.9h-5.7V1003.8z M510.3,832.9h5.9v5.9h-5.9L510.3,832.9L510.3,832.9z
				 M509.5,1003.8h5.7v5.9h-5.7V1003.8z M738,832.9h5.9v5.9H738V832.9z M737.2,918.1h5.7v5.9h-5.7V918.1z M737.2,1003.8h5.7v5.9
				h-5.7V1003.8z M794.1,917.9h17.7v5.9h-17.7V917.9z M794.1,1003.2h254.2v5.9H794.1V1003.2L794.1,1003.2z M794.1,831.9h17.7v5.9
				h-17.7V831.9z M794.1,959.3h17.7v5.9h-17.7V959.3z M794.1,873.3h17.7v5.9h-17.7V873.3z M794.1,787.6h17.7v5.9h-17.7V787.6z
				 M794.1,746.5h17.7v5.9h-17.7V746.5z M794.1,705.1h17.7v5.9h-17.7V705.1z M790.5,805.9l-0.8-0.8l9.5-9.3l0.5,0.5L790.5,805.9z
				 M789.7,820.5l0.8-0.5l9.3,9.3l-0.5,0.8L789.7,820.5z M1030.5,917.9h17.7v5.9h-17.7V917.9z M1030.5,831.9h17.7v5.9h-17.7V831.9z
				 M1030.5,959.3h17.7v5.9h-17.7V959.3z M1030.5,873.3h17.7v5.9h-17.7V873.3z M1030.5,787.6h17.7v5.9h-17.7V787.6z M1030.5,746.5
				h17.7v5.9h-17.7V746.5z M1030.5,705.1h17.7v5.9h-17.7V705.1z M790.5,849.1l-0.8-0.5l9.5-9.5l0.5,0.8L790.5,849.1z"
            />
            <path
              className="st0"
              d="M789.9,863.2l0.5-0.5l9.5,9.5l-0.5,0.5L789.9,863.2z"
            />
            <path
              className="st0"
              d="M790.5,892.1l-0.8-0.5l9.5-9.5l0.5,0.5L790.5,892.1z M789.7,907.1l0.8-0.8l9.3,9.5l-0.5,0.5L789.7,907.1z
				 M790.5,935.1l-0.8-0.8l9.5-9.3l0.5,0.5L790.5,935.1z M789.7,949l0.8-0.8l9.3,9.5l-0.5,0.5L789.7,949z M790.5,976.8l-0.8-0.8
				l9.5-9.5l0.5,0.8L790.5,976.8z M789.7,993.2l0.8-0.5l9.3,9.3l-0.5,0.8L789.7,993.2z M383.5,1089.1h126.8v3.1H383.5V1089.1z"
            />
            <path
              className="st0"
              d="M509.5,1089.1h5.7v5.9h-5.7V1089.1z M489.7,1047.7h5.9v11.8h-5.9V1047.7z M593.2,1047.7h5.9v11.8h-5.9
				V1047.7z M383.5,1047.7h3.1v44.2h-3.1V1047.7z M454.2,1091.2h2.8v15.7h-2.8V1091.2z M555.3,1089.7h5.7v5.9h-5.7V1089.7z
				 M600.7,1089.7h5.7v5.9h-5.7V1089.7z M646.4,1089.1h11.8v5.9h-11.8V1089.1z M737.5,1089.7h5.7v5.9h-5.7V1089.7z M581.4,1089.1
				h11.8v3.1h-11.8V1089.1z M515.9,1090.4l-0.5-0.5l6.7-6.9l0.8,0.5L515.9,1090.4z M527.8,1083.5l0.5-0.5l6.9,6.9l-0.5,0.5
				L527.8,1083.5z M535.7,1090.4l-0.5-0.5l6.7-6.9l0.8,0.5L535.7,1090.4z M532.4,1089.7h5.7v5.9h-5.7V1089.7z"
            />
            <path
              className="st0"
              d="M547.6,1083.5l0.5-0.5l6.9,6.9l-0.5,0.5L547.6,1083.5z"
            />
            <path
              className="st0"
              d="M562.2,1090.4l-0.5-0.5l6.9-6.9l0.5,0.5L562.2,1090.4z M574.2,1083.5l0.8-0.5l6.7,6.9l-0.5,0.5L574.2,1083.5
				z M794.1,1024.1h3.1v67.9h-3.1V1024.1L794.1,1024.1z"
            />
            <path
              className="st0"
              d="M794.1,1089.1h59.2v3.1h-59.2V1089.1z M794.1,1024.1h59.2v2.8h-59.2V1024.1z M723.1,1047.7h2.8v44.2h-2.8
				V1047.7z"
            />
            <path
              className="st0"
              d="M723.1,1089.1h14.7v3.1h-14.7V1089.1z M796.1,1011.2l1,0.8l-11.6,11.6l-0.8-0.8L796.1,1011.2z M279.9,574.9
				h2.8v174.5h-2.8V574.9z M282.9,746.5h147.6v2.8H282.9V746.5z M537,793.5h-56.1H537z M613.8,793.5h-58.9H613.8z M687.8,793.5
				h-59.2H687.8z M797.1,793.5h-91.8H797.1z M809,705.1h221.5v2.8H809V705.1z M480.7,574.9h2.8v44.2h-2.8V574.9z M684.8,574.9h3.1
				v218.7h-3.1V574.9z M510.3,619.4h3.1v133h-3.1L510.3,619.4L510.3,619.4z M513.4,749.6h38.3v3.1h-38.3V749.6z"
            />
            <path
              className="st0"
              d="M614.8,749.6h72.8v3.1h-72.8V749.6z M567.9,749.6H600v3.1h-32.1V749.6z"
            />
            <path
              className="st0"
              d="M551.4,751.4l9.5-9.5l0.5,0.5l-9.5,9.5L551.4,751.4z"
            />
            <path
              className="st0"
              d="M604.8,742.4l0.8-0.8l9.3,9.5l-0.5,0.5L604.8,742.4z M636.9,771.2l0.8-0.5l9.5,9.5l-0.8,0.5L636.9,771.2z
				 M1036.4,708.2h2.8v298.2h-2.8V708.2z M593.2,1089.1h138.6v3.1H593.2V1089.1z M721.5,1054.2c-2.8,0-1.5-0.3-1.8-0.5
				c-0.3-0.3-0.8-0.5-1.3-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1.3,0.5c-0.5,0-1-0.3-1.3-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5
				c-0.3,0.3-0.5,0.5-1.3,0.5c-0.5,0-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1.3,0.5
				c-0.5,0-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5
				s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5
				s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5
				s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5
				s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5
				s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5
				s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5
				s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5
				s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.5-1-0.5s-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-1-0.5-1-0.5v-0.3
				c0,0,0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.5,1,0.5
				s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1-0.5s0.8,0.3,1,0.5
				c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5
				s0.8,0.3,1,0.5s0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.5,1,0.5s0.8-0.3,1-0.5
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5
				s0.8-0.3,1-0.5s0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				s0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5
				s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5
				s0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.5,1,0.5
				s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				s0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1-0.5
				s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.5,1,0.5
				s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1.3-0.5c0.5,0,0.8,0.3,1,0.5c0.3,0.3,0.5,0.5,1,0.5s0.8-0.3,1-0.5s0.5-0.5,1-0.5
				s1.5,0.3,1.8,0.5c0.3,0.3-1.3,0.5,1.5,0.5v0.3H721.5z M488.2,1053.9c0,0-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3
				c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5
				c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3
				c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3
				c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3
				c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5
				s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3
				c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5
				c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3
				c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5
				s-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3
				c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3
				c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5
				s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3
				c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5
				c-0.3-0.3-0.5-0.3-0.8-0.3c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3
				c-0.5,0-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5s-0.8-0.3-1-0.5s-0.5-0.3-0.8-0.3s-0.5,0.3-0.8,0.3c-0.3,0.3-0.5,0.5-1,0.5
				s-1.3-0.3-1.5-0.5s-1.3-0.3-1.3-0.3v-0.3c0,0,1.3,0.3,1.5,0.5c0.3,0.3,0.8,0.3,1,0.3c0.5,0,0.8-0.3,1-0.3c0.3-0.3,0.5-0.5,1-0.5
				s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.8-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3
				c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				s0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				c0.3,0.3,0.5,0.3,0.8,0.3s0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.3,0.8,0.3s0.5-0.3,0.8-0.3
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.3,0.8,0.3s0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3
				c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				s0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3
				c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5
				c0.3,0.3,0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.5,0.3,0.8,0.3
				c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5s0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5
				s0.8,0.3,1,0.5s0.5,0.3,0.8,0.3c0.5,0,0.5-0.3,0.8-0.3c0.3-0.3,0.5-0.5,1-0.5s0.8,0.3,1,0.5c0.3,0.3,0.8,0.3,0.8,0.3
				L488.2,1053.9L488.2,1053.9z M469.7,728.7h-0.3c0,0,0.3,0,0.5-0.3c0.3-0.3,0.5,0,0.5-0.5s-0.3-0.5-0.5-0.8s-0.5-0.5-0.5-1
				s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-0.8c0-0.5-0.3-0.8-0.5-0.8c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1
				s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1
				c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1s0.5-0.5,0.5-1s-0.3-0.8-0.5-1
				c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1s0.5-0.5,0.5-1s-0.3-0.8-0.5-1c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1
				c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1
				s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1s0.5-0.5,0.5-1s-0.3-0.8-0.5-1
				c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1s0.5-0.5,0.5-1s-0.3-0.8-0.5-1c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1
				c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1
				s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1
				c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1s0.5-0.5,0.5-1s-0.3-0.8-0.5-1c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1s0.5-0.5,0.5-1
				s-0.3-0.8-0.5-1c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1
				c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1
				c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1s0.5-0.5,0.5-1s-0.3-0.8-0.5-1c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1s0.5-0.5,0.5-1
				s-0.3-0.8-0.5-1c-0.3-0.3-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1
				c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1s-0.5-0.5-0.5-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.5,0.5-1s-0.3-0.8-0.5-1
				c-0.3-0.3-0.5-0.5-0.5-1s0.3-1.3,0.5-1.5c0.3-0.3,0.5-1.5,0.5-1.5h0.3c0,0-0.3,1.3-0.5,1.5c-0.3,0.3-0.5,0.8-0.5,1.3
				s0.3,0.8,0.5,1s0.5,0.5,0.5,1.3c0,0.5-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1s-0.3,0.8-0.5,1
				c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1
				c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1
				s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1s-0.3,0.8-0.5,1
				c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1
				c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1
				s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1s-0.3,0.8-0.5,1
				c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1
				s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1s0.3,0.8,0.5,1
				c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1
				c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1
				s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1s0.3,0.8,0.5,1
				c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1
				c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1s0.5,0.5,0.5,1
				s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.5-0.5,1s0.3,0.8,0.5,1c0.3,0.3,0.5,0.5,0.5,1s-0.3,0.8-0.5,1s-0.5,0.5-0.5,1s0.3,0.8,0.5,1
				c0.3,0.3,0.5,0.5,0.5,1s-0.3,0-0.5,0.3C469.9,728.7,469.7,728.7,469.7,728.7L469.7,728.7z M985,999.4c0-0.3-0.3-0.5-0.5-0.5
				c-0.3-0.3-0.3-0.3-0.3-0.5c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5
				c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5s-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5s0.3-0.3,0.3-0.5
				c0.3-0.3,0.5-0.3,0.5-0.5c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5
				c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5s0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5s-0.3-0.5-0.5-0.5
				c-0.3-0.3-0.3-0.3-0.3-0.5c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5
				c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5s-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5s0.3-0.3,0.3-0.5
				c0.3-0.3,0.5-0.3,0.5-0.5c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5
				c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5s0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5s-0.3-0.5-0.5-0.5
				c-0.3-0.3-0.3-0.3-0.3-0.5c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5
				c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5s-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5s0.3-0.3,0.3-0.5
				c0.3-0.3,0.5-0.3,0.5-0.5c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5c0-0.3,0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.5
				c0-0.3-0.3-0.5-0.5-0.5c-0.3-0.3-0.3-0.3-0.3-0.5s0.3-0.3,0.3-0.5c0.3-0.3,0.5-0.3,0.5-0.8c0-0.3-0.3-0.5-0.5-0.8
				c-0.3-0.3-0.3-0.5-0.3-0.8s0.3-0.8,0.3-1v-0.3c-0.3,0.3-0.5,1.3-0.5,1.5s0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5
				c0,0.3-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5c0,0.3,0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5c0,0.3-0.3,0.3-0.3,0.5
				c-0.3,0.3-0.5,0.3-0.5,0.5s0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5s-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5
				c0,0.3,0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5c0,0.3-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5c0,0.3,0.3,0.5,0.5,0.5
				c0.3,0.3,0.3,0.3,0.3,0.5s-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5s0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5
				c0,0.3-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5c0,0.3,0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5c0,0.3-0.3,0.3-0.3,0.5
				c-0.3,0.3-0.5,0.3-0.5,0.5s0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5s-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5
				c0,0.3,0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5c0,0.3-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5c0,0.3,0.3,0.5,0.5,0.5
				c0.3,0.3,0.3,0.3,0.3,0.5s-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5s0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5
				c0,0.3-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5c0,0.3,0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5c0,0.3-0.3,0.3-0.3,0.5
				c-0.3,0.3-0.5,0.3-0.5,0.5s0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5s-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5
				c0,0.3,0.3,0.5,0.5,0.5c0.3,0.3,0.3,0.3,0.3,0.5c0,0.3-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5c0,0.3,0.3,0.5,0.5,0.5
				c0.3,0.3,0.3,0.3,0.3,0.5s-0.3,0.3-0.3,0.5c-0.3,0.3-0.5,0.3-0.5,0.5s0.3,0.5,0.5,0.5C984.7,998.9,985,999.1,985,999.4
				c-0.3,0.3-0.3,0.3-0.5,0.3 M986.5,963.1c0.3,0,0.5,0.3,0.5,0.5c0.3,0.3,0.3,0.5,0.5,0.5c0.3,0,0.3-0.3,0.5-0.5s0.3-0.5,0.8-0.5
				c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5
				c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5
				c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5
				c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5
				c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5
				c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5
				c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5
				c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5
				c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5
				c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5
				c0.3,0,0.5-0.3,0.5-0.5c0.3-0.3,0.3-0.5,0.8-0.5c0.3,0,0.5,0.3,0.8,0.5s0.3,0.5,0.5,0.5c0.3,0,0.5-0.3,0.5-0.5
				c-0.3,0.3-0.3,0.5-0.8,0.5s-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5
				c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5
				c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5
				c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5
				c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5
				c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5
				c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5
				c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5
				c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5
				c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5
				c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5
				c-0.3,0-0.5,0.3-0.5,0.5c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5c-0.3-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.3-0.5,0.5
				c-0.3,0.3-0.3,0.5-0.8,0.5c-0.3,0-0.5-0.3-0.8-0.5L986.5,963.1L986.5,963.1z M480.5,790.5h331.1v2.8H480.5V790.5z"
            />
            <path
              className="st0"
              d="M592.9,1047.7h3.1v44.2h-3.1V1047.7z M492.5,1048.8h3.1v42.7h-3.1V1048.8z M511.8,1091.2h3.1v15.7h-3.1
				L511.8,1091.2L511.8,1091.2z"
            />
            <path
              className="st0"
              d="M469.9,617.9h215.6v2.8H469.9V617.9z M535.5,751.9h2.8V792h-2.8V751.9z M645.4,779.1h2.8v12.3h-2.8V779.1z
				 M645.4,751.9h2.8v12.3h-2.8V751.9z M468.9,730.3h42.9v2.8h-42.9V730.3z"
            />
            <path
              className="st0"
              d="M479.9,732.3h2.8v61.2h-2.8V732.3z M849.1,1034.1h2.8v48.1h-2.8V1034.1z"
            />
            <path
              className="st0"
              d="M553.4,1150.6l2.6-15.2l5.9,1l-2.6,15.2L553.4,1150.6z"
            />
            <path
              className="st0"
              d="M597.2,1157.8l2.6-15.2l5.9,1l-2.6,15.2L597.2,1157.8z"
            />
            <path
              className="st0"
              d="M641.7,1165.5l2.6-15.2l5.9,1l-2.6,15.2L641.7,1165.5z"
            />
            <path
              className="st0"
              d="M685.5,1172.7l2.6-15.2l5.9,1l-2.6,15.2L685.5,1172.7z"
            />
            <path
              className="st0"
              d="M730.2,1180.2l2.6-15.2l5.9,1l-2.6,15.2L730.2,1180.2z"
            />
            <path
              className="st0"
              d="M774.7,1187.6l2.6-15.2l5.9,1l-2.6,15.2L774.7,1187.6z"
            />
            <path
              className="st0"
              d="M819.2,1195.1l2.6-15.2l5.9,1l-2.6,15.2L819.2,1195.1z"
            />
            <path
              className="st0"
              d="M863.2,1202.6l2.6-15.2l5.9,1l-2.6,15.2L863.2,1202.6z"
            />
            <path
              className="st0"
              d="M907.1,1210l2.6-15.2l5.9,1L913,1211L907.1,1210z"
            />
            <path
              className="st0"
              d="M517.7,1362.4l2.6-15.2l5.9,1l-2.6,15.2L517.7,1362.4z"
            />
            <path
              className="st0"
              d="M561.7,1369.8l2.6-15.2l5.9,1l-2.6,15.2L561.7,1369.8z"
            />
            <path
              className="st0"
              d="M606.2,1377.3l2.6-15.2l5.9,1l-2.6,15.2L606.2,1377.3z"
            />
            <path
              className="st0"
              d="M650,1384.5l2.6-15.2l5.9,1l-2.6,15.2L650,1384.5z"
            />
            <path
              className="st0"
              d="M694.5,1392.2l2.6-15.2l5.9,1l-2.6,15.2L694.5,1392.2z"
            />
            <path
              className="st0"
              d="M739.2,1399.4l2.6-15.2l5.9,1l-2.6,15.2L739.2,1399.4z"
            />
            <path
              className="st0"
              d="M783.7,1407.1l2.6-15.2l5.9,1l-2.6,15.2L783.7,1407.1z"
            />
            <path
              className="st0"
              d="M827.7,1414.6l2.6-15.2l5.9,1l-2.6,15.2L827.7,1414.6z"
            />
            <path
              className="st0"
              d="M871.4,1421.8l2.6-15.2l5.9,1l-2.6,15.2L871.4,1421.8z"
            />
            <path
              className="st0"
              d="M580.3,1258.7l10.3-61.5l1.3,0.3l-10.3,61.5L580.3,1258.7z"
            />
            <path
              className="st0"
              d="M534.1,1188.9l0.3-1.3l57.1,9.5l-0.3,1.3L534.1,1188.9z"
            />
            <path
              className="st0"
              d="M504.3,1184.3l0.3-1.3l15.4,2.6l-0.3,1.3L504.3,1184.3z"
            />
            <path
              className="st0"
              d="M522.8,1363.1l0.3-1.3l39.1,6.7l-0.3,1.3L522.8,1363.1z"
            />
            <path
              className="st0"
              d="M478.1,1346.7l0.3-1.3l42.9,6.9l-0.3,1.3L478.1,1346.7z"
            />
            <path
              className="st0"
              d="M584.4,1359.3l0.3-1.3l309.7,51.9l-0.3,1.3L584.4,1359.3z"
            />
            <path
              className="st0"
              d="M896.6,1391.2l0.3-1.3l42.4,7.2l-0.3,1.3L896.6,1391.2z"
            />
            <path
              className="st0"
              d="M922.9,1234.3l0.3-1.3l42.4,7.2l-0.3,1.3L922.9,1234.3z"
            />
            <path
              className="st0"
              d="M893.3,1410.5l3.3-20.3l1.3,0.3l-3.3,20.3L893.3,1410.5z"
            />
            <path
              className="st0"
              d="M922.9,1233.8l3.6-21.1l1.3,0.3l-3.6,21.1L922.9,1233.8z"
            />
            <path
              className="st0"
              d="M948.4,1236.6L948.4,1236.6l2.8-18.3h0.3L948.4,1236.6z"
            />
            <path
              className="st0"
              d="M951.5,1237.4l3.1-18.3h0.3L951.5,1237.4L951.5,1237.4z"
            />
            <path
              className="st0"
              d="M955.1,1237.9l3.3-18.3h0.3L955.1,1237.9L955.1,1237.9z"
            />
            <path
              className="st0"
              d="M958.7,1238.4l3.1-18.3h0.3L958.7,1238.4L958.7,1238.4z"
            />
            <path
              className="st0"
              d="M961.8,1239.2l3.1-18.3h0.3L961.8,1239.2L961.8,1239.2z"
            />
            <path
              className="st0"
              d="M965.4,1239.7L965.4,1239.7l2.8-18.5h0.3L965.4,1239.7z"
            />
            <path
              className="st0"
              d="M918.3,1414.6l3.1-18.3h0.3L918.3,1414.6L918.3,1414.6z"
            />
            <path
              className="st0"
              d="M922.2,1415.1L922.2,1415.1l2.8-18.5l0.3,0.3L922.2,1415.1z"
            />
            <path
              className="st0"
              d="M925.2,1415.6l3.1-18.3h0.3L925.2,1415.6L925.2,1415.6z"
            />
            <path
              className="st0"
              d="M928.8,1416.1l3.1-18.3h0.3L928.8,1416.1L928.8,1416.1z"
            />
            <path
              className="st0"
              d="M932.7,1416.9L932.7,1416.9l2.8-18.5h0.3L932.7,1416.9z M936,1417.4h-0.5l3.1-18.5l0.5,0.3L936,1417.4z
				 M889.1,1162.7l-17-2.8l5.7-29.8l-1-38.6h2.1l1,38.8l-5.1,27.8l12.3,2.1l0.3-41.1h2.1L889.1,1162.7z"
            />
            <path
              className="st0"
              d="M913.2,1198.7l-44.7-7.5l0.3-2.1l42.7,7.2l5.7-30.9l0.3-42.9h-12.9v-2.1h14.9l-0.3,45.3L913.2,1198.7z
				 M956.4,1199.2h-0.5l-0.5,3.6l93.4,15.9l-36,215.4l-88.7-14.7l-0.5-0.3l-0.5,3.6l92.1,15.7h0.5l37-220.8l0.5-1.8L956.4,1199.2z"
            />
            <path
              className="st0"
              d="M514.6,1137v-0.3l-0.3-46v-0.5h-3.1l0.3,46.3l-40.1,234.4h-0.3l-0.5,3.1l537.4,90.3h0.5l0.5-3.1l-534.9-89.8
				L514.6,1137z"
            />
            <path
              className="st0"
              d="M916.8,1429.2l2.6-15.2l5.9,1l-2.6,15.2L916.8,1429.2z"
            />
            <path
              className="st0"
              d="M960.2,1436.7l2.6-15.2l5.9,1l-2.6,15.2L960.2,1436.7z"
            />
            <path
              className="st0"
              d="M1005.2,1463.9l5.7-33.4l5.9,1l-5.7,33.4L1005.2,1463.9z"
            />
            <g>
              <path
                className="st0"
                d="M566.8,1353.4h-0.3c0-0.5,0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1
					s0.3-0.8,0.5-1s0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8
					s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8
					s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1
					s0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1
					s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8
					s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1s0.5-0.3,0.5-0.8
					s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1
					c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1
					s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1s0.5-0.3,0.5-0.8s0-0.5-0.3-0.8
					s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8
					s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1
					c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1s0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1
					s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8
					s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8s0-0.5-0.3-0.8s-0.3-0.5-0.3-1s0.3-0.8,0.5-1c0.3-0.3,0.5-0.3,0.5-0.8
					h0.3c0,0.5-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8
					s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1
					s-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1
					s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8
					s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1s-0.5,0.3-0.5,0.8
					s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1
					c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1
					s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1s-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8
					s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8
					s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1
					c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1s-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1
					s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8
					s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1c-0.3,0.3-0.5,0.3-0.5,0.8
					s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1s-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1
					c-0.3,0.3-0.5,0.3-0.5,0.8s0,0.5,0.3,0.8s0.3,0.5,0.3,1s-0.3,0.8-0.5,1C567.1,1352.8,566.8,1353.1,566.8,1353.4z"
              />
            </g>
            <path className="st0" d="M802.3,707.1h3.1v84.1h-3.1V707.1z" />
          </g>
          <g>
            <path
              className="st1"
              d="M537,604.2l-52.2,1.7v41.2h24.9v104.6h-27.5v57.7l192.7-0.2V605.6 M537,604.4l-52.2,1.4 M537,604.7
				l-52.2,1.4"
            />
            <path
              className="st0"
              d="M980.3,1213.8l-6.7-1.2l-5.7,34.5l-14.9-2.4l-24.4,145.9l14.9,2.4c-1.2,7.4-2.6,15.1-3.8,23.2l72.6,12.2
				l1.2,0.2l34-203.7L980.3,1213.8z"
            />
            <g>
              <g>
                <path
                  className="st1"
                  d="M831.3,1371.4l-14.9-2.4l24.4-145.9l14.9,2.4l5.7-34.5l6.7,1.2c0-0.5,0.2-1.2,0.2-1.7l-60.6-8.6l5.3-25.6
						v-41.7l-27.5-0.2v37.4l-14.1-2.2l4.8-26.6l0.2-36.4l-341.7,0.2l0.7,41.7l-37.6,219.8l496.7,83.6l5.5-24.9l-72.6-12.2
						C828.6,1386.5,830.1,1378.8,831.3,1371.4z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  className="st1"
                  d="M782.9,806.1h220.2v200.8H782.9V806.1z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  className="st1"
                  d="M482.6,751.5c-3.6,0,29,0.2,29,0.2s-0.2-70.4,0-105.2c4.3,0-30.4,0.2-26.1,0.2v-41.4h-190v162.6h187.2
						v-16.4H482.6z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  className="st1"
                  d="M785.5,809H482.6v-41H375.5v318.9h401.9v-79.8h8.1V809L785.5,809z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Layer_4">
        <g id="charger">
          <polygon
            className="st0 icon"
            points="549.5,910.2 541.8,923.8 545.7,923.8 545.7,933.4 553.5,919.8 549.5,919.8 		"
          />
          <path
            className="st0 icon"
            d="M547.6,891c-15.9,0-28.8,12.9-28.8,28.8c0,15.9,12.9,28.8,28.8,28.8c15.9,0,28.8-12.9,28.8-28.8
			C576.5,903.9,563.5,891,547.6,891z M559.3,935.4c0,2.2-1.8,3.9-3.9,3.9h-15.5c-2.2,0-3.9-1.8-3.9-3.9v-27.2c0-2.2,1.8-3.9,3.9-3.9
			h1.9v-1.9c0-1.1,0.8-1.9,1.9-1.9h7.8c1.1,0,1.9,0.8,1.9,1.9v1.9h1.9c2.2,0,3.9,1.8,3.9,3.9L559.3,935.4L559.3,935.4z"
          />
        </g>
        <path
          id="recycle"
          className="st0 icon"
          d="M926.9,721.5c-15.9,0-28.8,12.9-28.8,28.8s12.9,28.8,28.8,28.8c15.9,0,28.8-12.9,28.8-28.8
		C955.7,734.4,942.8,721.5,926.9,721.5z M922.2,734.6c1.1-1.8,2.9-3.1,4.8-3.1c1.9,0,3.6,1.3,4.7,3.1l4.2,7.1l1.9-1.2v8.2l-7-4
		l1.9-1.1l-4.2-7c-0.7-1.1-1.2-1.3-1.6-1.3s-1,0.2-1.7,1.3l-3.5,5.8l-3.1-1.8L922.2,734.6z M923.2,764.3h-8.9
		c-2.2,0-4.1-0.8-5.1-2.5c-1-1.7-0.7-3.7,0.4-5.7l3.9-6.7l-1.8-1.1l6.7-4v8.2l-1.9-1.2l-3.9,6.6l0,0c-0.7,1.2-0.6,1.8-0.4,2
		c0.1,0.2,0.6,0.7,1.9,0.7h8.9v3.6H923.2z M944.6,761.6c-1,1.7-3,2.5-5.2,2.5h-7.1v2.8l-7.3-4.6l7.3-4.6v2.8h7.1
		c1.3,0,1.8-0.5,1.9-0.7c0.1-0.4,0.2-0.8-0.4-1.9l0,0l-3-4.9l3.3-1.9l2.9,4.9l0,0l0,0C945.3,757.9,945.6,759.9,944.6,761.6z"
        />
        <path
          id="exit"
          className="st0 icon exit"
          d="M286.5,871.4v30.5h6.1l0,0c0.6,0,1.2-0.2,1.6-0.7l0.5-0.7l3.4,2.5l-0.5,0.7c-1.1,1.4-3,2.5-4.8,2.5
		l0,0h-6.1v8.7h13.2l3.6-9.6c0.2-0.6,0.1-1.2-0.1-1.7l-6.1-4.3c-1.6-1.2-2-3.4-1-5.1l4.1-7.1l-2-0.4c-0.5-0.1-1-0.1-1.4,0.1
		l-6.3,3.1l-1.7-3.5l6.3-3.1c1.4-0.7,3.3-1.1,4.7-0.6l2.4,0.8l3.4,1.4c1.2,0.5,2.2,1.4,2.6,2.5l1.6,2c0.4,0.6,0.8,1.1,1.6,1.1h5.8
		v4.3h-5.7c-2.2,0-4.1-1.2-5.2-3.1l-0.8-1.2l-3.5,6.1l3.9,3.4c1.6,1.6,2.2,4.1,1.3,6.1l-3.1,8.2h17.5v-43.6h-35.1L286.5,871.4
		L286.5,871.4z M307.8,884.4c-2.2,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9C311.7,882.7,310,884.4,307.8,884.4z"
        />
        <path
          id="exit_1_"
          className="st0 icon exit"
          d="M286.5,927.1v30.5h6.1l0,0c0.6,0,1.2-0.2,1.6-0.7l0.5-0.7l3.4,2.5l-0.5,0.7
		c-1.1,1.4-3,2.5-4.8,2.5l0,0h-6.1v8.7h13.2l3.6-9.6c0.2-0.6,0.1-1.2-0.1-1.7l-6.1-4.3c-1.6-1.2-2-3.4-1-5.1l4.1-7.1l-2-0.4
		c-0.5-0.1-1-0.1-1.4,0.1l-6.3,3.1l-1.7-3.5l6.3-3.1c1.4-0.7,3.3-1.1,4.7-0.6l2.4,0.8l3.4,1.4c1.2,0.5,2.2,1.4,2.6,2.5l1.6,2
		c0.4,0.6,0.8,1.1,1.6,1.1h5.8v4.3h-5.7c-2.2,0-4.1-1.2-5.2-3.1l-0.8-1.2l-3.5,6.1l3.9,3.4c1.6,1.6,2.2,4.1,1.3,6.1l-3.1,8.2h17.5
		v-43.6h-35.1L286.5,927.1L286.5,927.1z M307.8,940.2c-2.2,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9
		C311.7,938.5,310,940.2,307.8,940.2z"
        />
        <path
          id="stage"
          className="st0 icon"
          d="M889.5,1278.7c-15.9,0-28.8,12.9-28.8,28.8c0,15.9,12.9,28.8,28.8,28.8c15.9,0,28.8-12.9,28.8-28.8
		C918.4,1291.6,905.4,1278.7,889.5,1278.7z M897.6,1325.7h-16.1v-10h16.1V1325.7z M909.6,1305.6H907l-1.1,4.5
		c-0.2,1-1.1,1.6-1.9,1.6h-29c-1,0-1.7-0.6-1.9-1.6l-1.1-4.5h-2.6v-4h30.1v-4.6c-1.2-0.7-2-2-2-3.5c0-2.2,1.8-4,4-4s4,1.8,4,4
		c0,1.4-0.7,2.8-2,3.5v4.6h6v4H909.6z"
        />
        <path
          id="food"
          className="st0 icon"
          d="M925,891c-15.9,0-28.8,12.9-28.8,28.8c0,15.9,12.9,28.8,28.8,28.8c15.9,0,28.8-12.9,28.8-28.8
		C953.8,903.9,940.9,891,925,891z M918.3,928.4v5.8h5.7v3.7H909v-3.7h5.7v-5.8c-4.5-0.6-7.8-4.6-7.5-9.3l1.2-17h16.4l1.2,17
		C926.2,923.7,922.8,927.6,918.3,928.4z M942.8,915.2c0,4.1-3.4,7.5-7.5,7.5v15.1h-3.7v-15.1c-0.7,0-1.4-0.1-2.2-0.4
		c0.2-1.2,0.4-2.4,0.2-3.6l-0.2-3.5h2.2V902h3.7v13.2h3.7V902h3.7L942.8,915.2L942.8,915.2z"
        />
        <path
          id="info"
          className="st0 icon"
          d="M403.7,892.3c-15.9,0-28.8,12.9-28.8,28.8s12.9,28.8,28.8,28.8c15.9,0,28.8-12.9,28.8-28.8
		C432.6,905.2,419.6,892.3,403.7,892.3z M406.6,935.5h-5.8v-17.3h5.8V935.5z M406.6,912.5h-5.8v-5.8h5.8V912.5z"
        />
        <g id="charger_1_">
          <polygon
            className="st0 icon"
            points="388.5,654.8 380.7,668.4 384.7,668.4 384.7,678.2 392.4,664.5 388.5,664.5 		"
          />
          <path
            className="st0 icon"
            d="M386.6,635.8c-15.9,0-28.8,12.9-28.8,28.8s12.9,28.8,28.8,28.8c15.9,0,28.8-12.9,28.8-28.8
			C415.4,648.7,402.5,635.8,386.6,635.8z M398.2,680.1c0,2.2-1.8,3.9-3.9,3.9h-15.5c-2.2,0-3.9-1.8-3.9-3.9v-27.2
			c0-2.2,1.8-3.9,3.9-3.9h1.9v-1.9c0-1.1,0.8-1.9,1.9-1.9h7.8c1.1,0,1.9,0.8,1.9,1.9v1.9h1.9c2.2,0,3.9,1.8,3.9,3.9L398.2,680.1
			L398.2,680.1z"
          />
        </g>
        <path
          id="wheelchair"
          className="st0 icon"
          d="M774.7,1095c-15.9,0-28.8,12.9-28.8,28.8c0,15.9,12.9,28.8,28.8,28.8
		c15.9,0,28.8-12.9,28.8-28.8C803.6,1107.9,790.6,1095,774.7,1095z M775.9,1103.7c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4s-4-1.8-4-4
		C771.8,1105.5,773.6,1103.7,775.9,1103.7z M769.8,1144c-6.6,0-12-5.4-12-12c0-5.3,3.4-9.8,8.1-11.4v4.5c-2.4,1.4-4,4-4,7
		c0,4.5,3.6,8.1,8.1,8.1c3,0,5.5-1.7,7-4h4.5C779.5,1140.7,775.1,1144,769.8,1144z M788.3,1141l-5.4-9.2H780h-6c-2.3,0-4-1.8-4-4
		v-8.1c0-2.3,1.8-4,4-4h2c2.3,0,4,1.8,4,4v8.1h4c0.7,0,1.3,0.4,1.7,1l6,10.1L788.3,1141z"
        />
        <path
          id="wheelchair_1_"
          className="st0 icon"
          d="M667.4,754.7c-9.5,0-17.2,7.7-17.2,17.2c0,9.5,7.7,17.2,17.2,17.2
		c9.5,0,17.2-7.7,17.2-17.2C684.5,762.4,676.9,754.7,667.4,754.7z M668.1,759.9c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4
		c-1.3,0-2.4-1.1-2.4-2.4C665.7,761,666.8,759.9,668.1,759.9z M664.5,783.9c-4,0-7.2-3.3-7.2-7.2c0-3.1,2-5.8,4.8-6.7v2.6
		c-1.4,0.8-2.4,2.4-2.4,4.1c0,2.6,2.2,4.8,4.8,4.8c1.8,0,3.3-1,4.1-2.4h2.6C670.2,781.8,667.6,783.9,664.5,783.9z M675.4,782.1
		l-3.3-5.4h-1.7h-3.6c-1.3,0-2.4-1.1-2.4-2.4v-4.8c0-1.3,1.1-2.4,2.4-2.4h1.2c1.3,0,2.4,1.1,2.4,2.4v4.8h2.4c0.5,0,0.8,0.2,1.1,0.6
		l3.6,6L675.4,782.1z"
        />
        <g id="genderless_bathroom_1_">
          <path
            className="st0 icon"
            d="M527.4,1268.4h-6.3c-0.3,0-0.5,0.3-0.5,0.6v10.9c0,0.3,0.5,1,2.4,1.1c0-0.5,0.5-0.8,1.2-0.8
			c0.7,0,1.2,0.4,1.2,0.8c1.9-0.2,2.4-0.9,2.4-1.1h0V1269C527.9,1268.7,527.7,1268.4,527.4,1268.4z"
          />
          <path
            className="st0 icon"
            d="M534.1,1248.5c-15.9,0-28.8,12.9-28.8,28.8c0,15.9,12.9,28.8,28.8,28.8s28.8-12.9,28.8-28.8
			C562.9,1261.4,550,1248.5,534.1,1248.5z M541.1,1259.6h2.2c4.4,0,6.6,5.3,6.6,10.6c0,3.2-0.5,7-2.9,7c-2.8,0-3.7,0-3.7,0h-2.2
			c0,0-0.9,0-3.7,0c-2.4,0-2.9-3.8-2.9-7C534.5,1264.9,536.7,1259.6,541.1,1259.6z M530.7,1278.9c0,2.9-1.8,4.4-5.5,4.6v2.5h-2v-2.5
			c-3.7-0.2-5.5-1.7-5.5-4.6v-11.2c0-1,0.8-1.8,1.8-1.8h3.6v-1.4h-0.1c-0.2,0-0.3-0.1-0.3-0.3v-0.3h-2.4c-0.2,0-0.3-0.1-0.3-0.3
			v-0.9c0-0.2,0.1-0.3,0.3-0.3h2.4v-0.4c0-0.2,0.1-0.3,0.3-0.3h0.3v-2.1h1.6v2.1h0.3c0.2,0,0.3,0.1,0.3,0.3v2.1
			c0,0.2-0.1,0.3-0.3,0.3h-0.1v1.4h3.6c1,0,1.8,0.8,1.8,1.8V1278.9z M550,1285.4c0,2.7-4.4,3.3-4.4,4.8c0,1.5,4.3,1.3,4.3,2.4v1.1
			c0,0.7-0.5,1.3-1.2,1.3h-12.8c-0.6,0-1.2-0.6-1.2-1.3v-1.1c0-1.1,4.3-0.9,4.3-2.4c0-1.5-4.4-2.1-4.4-4.8v-2.1
			c0-0.7,0.5-1.3,1.2-1.3h13.1c0.7,0,1.2,0.6,1.3,1.3V1285.4z M548.9,1281.1h-13.3c-0.9,0-1.5-0.7-1.5-1.5c0-0.9,0.7-1.5,1.5-1.5
			h13.2c0.9,0,1.5,0.7,1.6,1.5C550.4,1280.5,549.7,1281.1,548.9,1281.1z"
          />
        </g>
        <g id="genderless_bathroom_2_">
          <path
            className="st0 icon"
            d="M586.5,675.4h-6.3c-0.3,0-0.5,0.3-0.5,0.6V687c0,0.3,0.5,1,2.4,1.1c0-0.5,0.5-0.8,1.2-0.8
			c0.7,0,1.2,0.4,1.2,0.8c1.9-0.2,2.4-0.9,2.4-1.1h0V676C587,675.7,586.8,675.4,586.5,675.4z"
          />
          <path
            className="st0 icon"
            d="M593.2,655.5c-15.9,0-28.8,12.9-28.8,28.8c0,15.9,12.9,28.8,28.8,28.8s28.8-12.9,28.8-28.8
			C622,668.4,609.1,655.5,593.2,655.5z M600.2,666.6h2.2c4.4,0,6.6,5.3,6.6,10.6c0,3.2-0.5,7-2.9,7c-2.8,0-3.7,0-3.7,0h-2.2
			c0,0-0.9,0-3.7,0c-2.4,0-2.9-3.8-2.9-7C593.6,671.9,595.8,666.6,600.2,666.6z M589.8,685.9c0,2.9-1.8,4.4-5.5,4.6v2.5h-2v-2.5
			c-3.7-0.2-5.5-1.7-5.5-4.6v-11.2c0-1,0.8-1.8,1.8-1.8h3.6v-1.4h-0.1c-0.2,0-0.3-0.1-0.3-0.3v-0.3h-2.4c-0.2,0-0.3-0.1-0.3-0.3
			v-0.9c0-0.2,0.1-0.3,0.3-0.3h2.4v-0.4c0-0.2,0.1-0.3,0.3-0.3h0.3v-2.1h1.6v2.1h0.3c0.2,0,0.3,0.1,0.3,0.3v2.1
			c0,0.2-0.1,0.3-0.3,0.3h-0.1v1.4h3.6c1,0,1.8,0.8,1.8,1.8V685.9z M609.1,692.4c0,2.7-4.4,3.3-4.4,4.8s4.3,1.3,4.3,2.4v1.1
			c0,0.7-0.5,1.3-1.2,1.3h-12.8c-0.6,0-1.2-0.6-1.2-1.3v-1.1c0-1.1,4.3-0.9,4.3-2.4c0-1.5-4.4-2.1-4.4-4.8v-2.1
			c0-0.7,0.5-1.3,1.2-1.3h13.1c0.7,0,1.2,0.6,1.3,1.3V692.4z M608,688.1h-13.3c-0.9,0-1.5-0.7-1.5-1.5c0-0.9,0.7-1.5,1.5-1.5h13.2
			c0.9,0,1.5,0.7,1.6,1.5C609.5,687.5,608.8,688.1,608,688.1z"
          />
        </g>
      </g>
    </svg>
  </div>
)
