import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import './index.scss'

const JSVisual = ({ presentation, midSlide }) => {
  return (
    <>
      <div className="topleft"></div>
      <div className="bottomright"></div>
    </>
  )
}

export default JSVisual
